import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ScreenNames } from '../ScreenNames';
import { t } from '../services/translations';
import BackIcon from '../components/atomic/BackIcon';
import ListBookingScreen from '../screens/loggedIn/contribution/booking/ListBookingScreen';
import CreateBookingScreen from '../screens/loggedIn/contribution/booking/CreateBookingScreen';
import UpdateBookingScreen from '../screens/loggedIn/contribution/booking/UpdateBookingScreen';

const Stack = createStackNavigator();

export function BookingRouter() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: true, headerBackTitleVisible: false, headerBackImage: BackIcon }}>
      <Stack.Screen
        name={ScreenNames.BookingMain}
        component={ListBookingScreen}
        options={{
          title: t('bookings'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.BookingCreate}
        component={CreateBookingScreen}
        options={{
          title: t('bookings'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.BookingUpdate}
        component={UpdateBookingScreen}
        options={{
          title: t('bookings'),
        }}
      />
    </Stack.Navigator>
  );
}
