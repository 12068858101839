import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import Form from '../../../../components/atomic/formik/Form';
import { setContributionsListNeedsRefresh } from '../../../../store/action';
import { alertInfo } from '../../../../services/utils';
import { t } from '../../../../services/translations';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import BookingResourcesFormFields, { schema } from './BookingResourcesFormFields';
import { createBookingResource } from '../../../../services/api/booking.contribution.api';
import { CreateContributionBookingResource } from '../../../../entities/CreateContributionBookingResource';

export default function CreateBookingResourcesScreen() {
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const { mutateAsync, isError } = useMutation({
    mutationFn: async (values: CreateContributionBookingResource) => {
      await createBookingResource(user, values);
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
  });

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <Form initialValues={{}} onSubmit={mutateAsync as any} validationSchema={schema} validateOnMount>
      <BookingResourcesFormFields />
    </Form>
  );
}
