import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { fr, uk, de, es, it } from 'date-fns/locale';

import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/it';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: require('../languages/en.json'),
  },
  fr: {
    translation: require('../languages/fr.json'),
  },
  de: {
    translation: require('../languages/de.json'),
  },
  es: {
    translation: require('../languages/es.json'),
  },
  it: {
    translation: require('../languages/it.json'),
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    //lng: Localization.locale,
    lng: 'fr-FR',
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'fr-FR',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export const t = (key: string, params?: { [key: string]: any }) => i18n.t(key, params) as any as string;
export const changeLanguage = (key: any) => i18n.changeLanguage(key);
export const language = () => {
  // if (!PROFILE_LANGUAGE_ENABLED)
  //     return "fr-FR"
  return availableLanguages.filter((v, i) => v.code == i18n.language).length > 0
    ? i18n.language
    : availableLanguages[0].code;
};

export const dateLocale = () => {
  return availableLanguages.filter((v, i) => v.code == i18n.language).length > 0 ? fr : availableLanguages[0].dateFns;
};

export const availableLanguages = [
  {
    code: 'fr-FR',
    text: 'Français 🇫🇷',
    dateFns: fr,
  },
  {
    code: 'en-GB',
    text: 'English 🇬🇧 🇺🇸',
    dateFns: uk,
  },
  {
    code: 'de-DE',
    text: 'Deutsch 🇩🇪',
    dateFns: de,
  },
  {
    code: 'es-ES',
    text: 'Español 🇪🇸',
    dateFns: es,
  },
  {
    code: 'it-IT',
    text: 'Italiano 🇮🇹',
    dateFns: it,
  },
];
