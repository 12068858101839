import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import Form from '../../../../components/atomic/formik/Form';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import { alertInfo, applyDateOffset } from '../../../../services/utils';
import { t } from '../../../../services/translations';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import BookingFormFields, { schema } from './BookingFormFields';
import { getBookingDetails, updateBooking } from '../../../../services/api/booking.contribution.api';
import { CreateContributionBooking } from '../../../../entities/CreateContributionBooking';
import { View } from 'react-native';

export type UpdateBookingScreenProps = {
  route: {
    params: {
      id?: string;
    };
  };
};

export default function UpdateBookingScreen({ route }: UpdateBookingScreenProps) {
  const user = useLoggedUser();
  const id = (route.params.id && parseInt(route.params.id, 10)) || null;
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const {
    data,
    isLoading: isLoadingQuery,
    isError: isErrorQuery,
    isFetched,
  } = useQuery(['bookingDetails', id], () => getBookingDetails(user, id || 0), { cacheTime: 0 });

  const {
    mutateAsync,
    isLoading: isLoadingMutation,
    isError: isErrorMutation,
  } = useMutation({
    mutationFn: async (values: CreateContributionBooking) => {
      await updateBooking(user, id || 0, {
        ...values,
        begin: new Date(applyDateOffset(values.begin)),
        end: new Date(applyDateOffset(values.end)),
      });
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
  });

  const isLoading = isLoadingQuery || isLoadingMutation;
  const isError = isErrorQuery || isErrorMutation;

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return isFetched ? (
    <Form
      initialValues={{
        contributionBookingResourceId: data?.contributionBookingResourceId,
        begin: data?.begin && new Date(data.begin),
        end: data?.end && new Date(data.end),
        hasIntersection: true,
      }}
      onSubmit={mutateAsync as any}
      validationSchema={schema(user, id || undefined)}
      validateOnMount
    >
      <BookingFormFields />
    </Form>
  ) : (
    <View />
  );
}
