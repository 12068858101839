import React from 'react';
import { useField } from 'formik';
import AppText, { AppTextProps, Mode } from '../AppText';

export type FieldHelperProps = Omit<AppTextProps, 'children'> & {
  name: string;
  displayIfNotTouched?: boolean;
};

export default function FieldHelper({ name, displayIfNotTouched, mode, ...rest }: FieldHelperProps) {
  const [, meta] = useField(name);

  if ((meta.touched || displayIfNotTouched) && meta.error) {
    return (
      <AppText mode={mode || Mode.ERROR} {...rest}>
        {meta.error}
      </AppText>
    );
  } else {
    return null;
  }
}
