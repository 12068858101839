import React from 'react';
import { t } from '../services/translations';
import { DirectoryScreen } from '../screens/loggedIn/DirectoryScreen';
import { ContributionType } from '../types';
import { createStackNavigator } from '@react-navigation/stack';
import { CompanyAdminMainScreen } from '../screens/loggedIn/admin/CompanyAdminMainScreen';
import { SuggestionsAdministrationRoute } from './SuggestionsAdministrationRoute';
import { CompanyNewsAdministrationRoute } from './CompanyNewsAdministrationRoute';
import { SurveyAdministrationRoute } from './SurveyAdministrationRoute';
import { ContributionListScreen } from '../screens/loggedIn/contribution/ContributionListScreen';
import { GetContributionsMode } from '../services/api/getContributionsMode';
import { ScreenNames } from '../ScreenNames';
import { CategoriesAdministrationRoute } from './CategoriesAdministrationRoute';
import { OrganizationalChartAdminRouter } from './OrganizationalChartAdminRouter';
import { hasLicenceBooking, hasLicenceOrganizationalChart } from '../services/licences';
import { CompanyGroupAdminRouter } from './CompanyGroupAdminRouter';
import { ValidationMapAdministrationRoute } from './ValidationMapAdministrationRoute';
import StatisticsScreen from '../screens/loggedIn/admin/StatisticsScreen';
import useLoggedUser from '../hooks/useLoggedUser';
import AdminScreenRoute from './AdminScreenRoute';
import EditSiteGeoScreenSettings from '../screens/loggedIn/admin/EditSiteGeoScreenSettings';
import BackIcon from '../components/atomic/BackIcon';
import BirthdaysScreen from '../screens/loggedIn/admin/BirthdaysScreen';
import { BookingAdminRouter } from './BookingAdminRouter';

const Stack = createStackNavigator();

export function AdminRoute() {
  const user = useLoggedUser();

  return (
    <Stack.Navigator screenOptions={{ headerShown: true, headerBackTitleVisible: false, headerBackImage: BackIcon }}>
      <Stack.Screen
        name={ScreenNames.CompanyAdminMain}
        component={CompanyAdminMainScreen}
        initialParams={{
          isAdminView: true,
        }}
        options={{
          headerShown: false,
          title: t('admin_page_title'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.StatisticsAdministration}
        component={StatisticsScreen}
        options={{
          headerShown: true,
          title: t('statistics'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.SuggestionsAdministration}
        component={SuggestionsAdministrationRoute}
        initialParams={{
          isAdminView: true,
        }}
        options={{
          headerShown: true,
          title: t('suggestions_box'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.DirectoryAdministration}
        component={DirectoryScreen}
        options={{
          headerShown: true,
          title: t('contributors'),
        }}
        initialParams={{ allowEdit: true }}
      />
      <Stack.Screen
        name={ScreenNames.UserBirthDays}
        component={BirthdaysScreen}
        options={{
          headerShown: true,
          title: t('birthdays'),
        }}
        initialParams={{ allowEdit: true }}
      />
      <Stack.Screen
        name={ScreenNames.ContributionsAdministration}
        component={ContributionListScreen}
        options={{
          headerShown: true,
          title: t('contributions'),
        }}
        initialParams={{
          getContributionsMode: GetContributionsMode.admin_all,
          contributionType: ContributionType.ALL,
          isAdminView: true,
        }}
      />
      <Stack.Screen
        name={ScreenNames.CompanyNewsAdministration}
        component={CompanyNewsAdministrationRoute}
        initialParams={{
          isAdminView: true,
        }}
        options={{
          headerShown: true,
          title: t('company_news'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.CategoriesAdministration}
        component={CategoriesAdministrationRoute}
        initialParams={{
          isAdminView: true,
        }}
        options={{
          headerShown: true,
          title: t('categories_menu'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.SurveyAdministration}
        component={SurveyAdministrationRoute}
        options={{
          headerShown: true,
          title: t('surveys'),
        }}
      />
      {hasLicenceOrganizationalChart(user) && (
        <Stack.Screen
          name={ScreenNames.OrganizationalChartAdmin}
          component={OrganizationalChartAdminRouter}
          initialParams={{
            isAdminView: true,
          }}
          options={{
            headerShown: false,
            title: t('surveys'),
          }}
        />
      )}
      {hasLicenceBooking(user) && (
        <Stack.Screen
          name={ScreenNames.BookingAdmin}
          component={BookingAdminRouter}
          initialParams={{
            isAdminView: true,
          }}
          options={{
            headerShown: false,
            title: t('bookings'),
          }}
        />
      )}
      <Stack.Screen
        name={ScreenNames.CompanyGroupAdmin}
        component={CompanyGroupAdminRouter}
        initialParams={{
          isAdminView: true,
        }}
        options={{
          headerShown: false,
          title: t('groups'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.InternScreenAdmin}
        component={EditSiteGeoScreenSettings}
        options={{
          headerShown: true,
          title: t('intern_tv_screen'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.ScreenAdmin}
        component={AdminScreenRoute}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={ScreenNames.ValidationMap}
        component={ValidationMapAdministrationRoute}
        initialParams={{
          isAdminView: true,
        }}
        options={{
          headerShown: false,
          title: t('validation_map'),
        }}
      />
    </Stack.Navigator>
  );
}
