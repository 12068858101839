export const ScreenNames = {
  CompanyNewsScreen: 'news',
  ContributionsAllScreen: 'all',
  Login: 'login',
  LoginAs: 'loginas',
  Main: 'main',
  Logout: 'logout',
  Registration: 'registration',
  ValidationCode: 'validation-code',
  AdminRoute: 'administration',
  ResetPasswordSendCode: 'reset-password-send-code',
  ResetPasswordSendCodeSuccess: 'reset-password-send-code-success',
  ResetPassword: 'reset-password',
  NoProfessionalAddress: 'NoProfessionalAddress',
  ContributionListScreenOffers: 'Offers',
  ContributionListScreenRequests: 'Requests',
  ContributionFormScreen: 'Publish',
  ContributionListScreenMine: 'CurrentUserList',
  ContributionListScreen: 'List',
  ContributionListScreenParticipation: 'Participation',
  SuggestionBoxScreen: 'Suggestion',
  ContributionTabs: 'Tabs',
  ContributionEditFormScreen: 'edit',
  SuggestionsToApproveList: 'SuggestionsToApprove',
  ReviewedSuggestionsList: 'Reviewed',
  CompanyNewsToApproveList: 'NewsToApprove',
  ReviewedCompanyNewsList: 'ReviewedCompanyNewsList',
  SurveysToApproveList: 'SurveysToApprove',
  ApprovedSurveysList: 'ApprovedSurveys',
  LoggedInHome: 'home',
  CompanyAdminMain: 'administration',
  SellMainRouter: 'sell',
  ActivityMainRouter: 'activity',
  MutualAidMainRouter: 'mutual-aid',
  CarPoolMainRouter: 'carpool',
  EventMainRouter: 'event',
  TrainingMainRouter: 'training',
  BookingRouter: 'booking',
  OrganizationCharts: 'organization-charts',
  LostPropertyMainRouter: 'lost-property',
  SurveyMainRouter: 'survey',
  SuggestionMainRouter: 'suggestionMain',
  DocumentMainRouter: 'document',
  EmergencySmsRouter: 'emergency-sms',
  Directory: 'directory',
  LoggedInDrawerNavigation: 'navigation',
  Profile: 'Profile',
  EditMyProfile: 'EditMyProfile',
  EditUser: 'EditUser',
  EditPasswordScreen: 'EditPassword',
  ContributionItemDetailScreen: 'detail',
  StatisticsAdministration: 'statistics',
  SuggestionsAdministration: 'suggestions',
  DirectoryAdministration: 'directory',
  UserBirthDays: 'birth-days',
  ContributionsAdministration: 'contributions',
  CompanyNewsAdministration: 'company-news',
  SurveyAdministration: 'surveys',
  Messaging: 'messaging',
  MessagingMain: 'messaging-main',
  MessagingThread: 'messaging-thread',
  MessagingCreateThread: 'messaging-create-thread',
  OrganizationalChartAdmin: 'organizational-chart',
  OrganizationalChartAdminMain: 'organizational-chart-main',
  OrganizationalChartAdminCreate: 'organizational-chart-create',
  OrganizationalChartAdminUpdate: 'organizational-chart-update',
  BookingAdmin: 'booking',
  BookingAdminMain: 'list',
  BookingAdminCreate: 'create',
  BookingAdminUpdate: 'update',
  BookingMain: 'list',
  BookingCreate: 'create',
  BookingUpdate: 'update',
  CompanyGroupAdmin: 'company-group',
  CompanyGroupAdminMain: 'company-group-main',
  CompanyGroupAdminCreate: 'company-group-create',
  CompanyGroupAdminUpdate: 'company-group-update',
  CategoriesAdministration: 'categories',
  CreateCategory: 'category-creation',
  ManageCategories: 'categories-manage',
  ValidationMap: 'validation-map',
  ValidationMapUpdate: 'validation-map-update',

  // Screen Settings
  InternScreenAdmin: 'intern-screen',
  ScreenAdmin: 'screen',
  ScreenSettingsList: 'settings',
  ScreenSettingsCreate: 'settings-create',
  ScreenSettingsUpdate: 'settings-update',
  ScreenPlaylistList: 'playlists',
  ScreenPlaylistCreate: 'playlist-create',
  ScreenPlaylistUpdate: 'playlist-update',
  ScreenMediasList: 'medias',

  // Users
  ContributeurList: 'contributeur-list',
  ContributeurCreate: 'contributeur-create',

  // Kiosk
  KiosqueFolder: 'folder',
  KiosqueCreateDocument: 'create-document',
  KiosqueCreateFolder: 'create-folder',
  KiosqueUpdateDocument: 'update-document',
  KiosqueUpdateFolder: 'update-folder',

  // Forms
  CreateCompanyNews: 'create-company-news',
  EditCompanyNews: 'edit-company-news',
  DuplicateCompanyNews: 'duplicate-company-news',

  // Details
  DetailCompanyNews: 'company-news',

  // Survey
  SurveyList: 'list',
  SurveyDetails: 'details',
  SurveyAdminList: 'admin',
  SurveyCreate: 'create',
  SurveyUpdate: 'update',

  // Custom modules
  CustomModuleTabs: 'tabs',
  CustomModuleList: 'list',
  CustomModuleDetails: 'custom-details',
  CustomModuleAdminList: 'admin',
  CustomModuleCreate: 'create',
  CustomModuleUpdate: 'update',

  // Emergency SMS
  EmergencySmsTabs: 'tabs',
  EmergencySmsListTemplates: 'templates',
  EmergencySmsListMyTemplates: 'my-templates',
  EmergencySmsCreateTemplate: 'create',
  EmergencySmsUpdateTemplate: 'update',
  EmergencySmsSend: 'send',
  EmergencySmsSendTemplate: 'send-template',
};
