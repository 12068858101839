import axios from 'axios';
import { headers } from './base';
import { apiUrlBase } from '../../../configuration';
import { UserSaved } from '../storage';
import { ContributionBookingSummary } from '../../entities/ContributionBookingSummary';
import { ContributionBookingResourceSummary } from '../../entities/ContributionBookingResourceSummary';
import { ContributionBookingResourceDetails } from '../../entities/ContributionBookingResourceDetails';
import { UpdateContributionBookingResource } from '../../entities/UpdateContributionBookingResource';
import { CreateContributionBookingResource } from '../../entities/CreateContributionBookingResource';
import { ContributionBookingDetails } from '../../entities/ContributionBookingDetails';
import { CreateContributionBooking } from '../../entities/CreateContributionBooking';
import { UpdateContributionBooking } from '../../entities/UpdateContributionBooking';

export async function listBookingByResourceId(
  user: UserSaved,
  resourceId: number,
  begin: Date,
  end: Date
): Promise<ContributionBookingSummary[]> {
  return axios
    .get(`${apiUrlBase}ContributionBooking/by-resource/${resourceId}`, {
      headers: headers(user.token),
      params: {
        begin: begin.toISOString(),
        end: end.toISOString(),
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function checkResourceIntersectWithOtherBooking(
  user: UserSaved,
  contributionResourceId: number,
  begin: Date,
  end: Date,
  ignoreBookingId?: number
): Promise<boolean> {
  return axios
    .get(`${apiUrlBase}ContributionBooking/by-resource/${contributionResourceId}/intersect`, {
      headers: headers(user.token),
      params: {
        begin: begin.toISOString(),
        end: end.toISOString(),
        ignoreBookingId,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function getBookingDetails(user: UserSaved, id: number): Promise<ContributionBookingDetails> {
  return axios
    .get(`${apiUrlBase}ContributionBooking/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function createBooking(
  user: UserSaved,
  body: CreateContributionBooking
): Promise<ContributionBookingDetails> {
  return axios
    .post(`${apiUrlBase}ContributionBooking`, body, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function updateBooking(
  user: UserSaved,
  id: number,
  body: UpdateContributionBooking
): Promise<ContributionBookingDetails> {
  return axios
    .put(`${apiUrlBase}ContributionBooking/${id}`, body, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function deleteBooking(user: UserSaved, id: number) {
  return axios
    .delete(`${apiUrlBase}ContributionBooking/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      return response;
    });
}

export async function getBookingResources(user: UserSaved): Promise<ContributionBookingResourceSummary[]> {
  return axios
    .get(`${apiUrlBase}ContributionBookingResource`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function getAdminBookingResources(user: UserSaved): Promise<ContributionBookingResourceSummary[]> {
  return axios
    .get(`${apiUrlBase}ContributionBookingResource/admin`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function getBookingResourceDetails(
  user: UserSaved,
  id: number
): Promise<ContributionBookingResourceDetails> {
  return axios
    .get(`${apiUrlBase}ContributionBookingResource/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function createBookingResource(
  user: UserSaved,
  body: CreateContributionBookingResource
): Promise<ContributionBookingResourceDetails> {
  return axios
    .post(`${apiUrlBase}ContributionBookingResource`, body, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function updateBookingResource(
  user: UserSaved,
  id: number,
  body: UpdateContributionBookingResource
): Promise<ContributionBookingResourceDetails> {
  return axios
    .put(`${apiUrlBase}ContributionBookingResource/${id}`, body, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function updateBookingResourceOrder(
  user: UserSaved,
  id: number,
  index: number
): Promise<ContributionBookingResourceSummary[]> {
  return axios
    .put(
      `${apiUrlBase}ContributionBookingResource/${id}/order`,
      { index },
      {
        headers: headers(user.token),
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function deleteBookingResource(user: UserSaved, id: number): Promise<void> {
  return axios
    .delete(`${apiUrlBase}ContributionBookingResource/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}
