import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { t } from '../../../../services/translations';
import { alertInfo } from '../../../../services/utils';
import { setLoading } from '../../../../store/action';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import { useQuery } from '@tanstack/react-query';
import { getBookingResources } from '../../../../services/api/booking.contribution.api';
import { ContributionBookingResourceSummary } from '../../../../entities/ContributionBookingResourceSummary';
import HorizontalFlatList from '../../../../components/containers/HorizontalList';
import { Chip } from '../../../../components/atomic/Chip';
import { contributionColors } from '../../../../services/api/contributions';
import { PRIMARY_COLOR } from '../../../../styles/appColor';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { ScreenNames } from '../../../../ScreenNames';
import BookingCalendar from '../../../../components/molecules/BookingCalendar';
import { FAB } from '@rneui/base';
import AppText from '../../../../components/atomic/AppText';

const colorList = Object.values(contributionColors);

export default function ListBookingScreen() {
  const loggedUser = useLoggedUser();
  const dispatch = useDispatch();
  const navigation = useNavigation<NavigationProp<any>>();
  const [selectedResource, setSelectedResource] = useState<ContributionBookingResourceSummary | null>(null);

  const {
    data: resources,
    isLoading,
    refetch: refresh,
  } = useQuery(['booking-resources', loggedUser?.id], () => getBookingResources(loggedUser), {
    onError: () => {
      alertInfo(t('unknown_error'));
    },
  });

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  return (
    <View style={styles.container}>
      <View style={styles.labelContainers}>
        <HorizontalFlatList
          data={resources || []}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item, index }) => (
            <Chip
              key={item.id}
              text={item.name}
              style={styles.chip}
              color={colorList[index % colorList.length]}
              selected={selectedResource?.id === item.id}
              onPress={() => setSelectedResource(item)}
            />
          )}
        />
      </View>
      {selectedResource ? (
        <BookingCalendar
          resourceId={selectedResource.id}
          onPress={(date) => {
            navigation.navigate(ScreenNames.BookingCreate, { begin: date.toISOString() });
          }}
        />
      ) : (
        <View style={styles.emptyContainer}>
          <AppText>{t('please_select_resource')}</AppText>
        </View>
      )}
      <FAB
        color={PRIMARY_COLOR}
        icon={<FontAwesome6 size={15} name="plus" color="#fff" />}
        onPress={() => {
          navigation.navigate(ScreenNames.BookingCreate);
        }}
        placement="left"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingBottom: 80,
  },
  labelContainers: {
    marginVertical: 10,
    paddingLeft: 10,
  },
  chip: {
    marginRight: 10,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
