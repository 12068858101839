import React from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Yup from 'yup';
import ContributionSpreadingField, {
  contributionSpreadingFieldValidators,
} from '../../../../components/atomic/formik/ContributionSpreadingField';
import SubmitButton from '../../../../components/atomic/formik/SubmitButton';
import TextField from '../../../../components/atomic/formik/TextField';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { t } from '../../../../services/translations';

export const schema = Yup.object({
  name: Yup.string().min(1).required(t('field_required')),
  ...contributionSpreadingFieldValidators,
});

export type BookingResourcesFormFieldsProps = {
  isEditing?: boolean | undefined;
};

export default function BookingResourcesFormFields({ isEditing }: BookingResourcesFormFieldsProps) {
  return (
    <AppScreen>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        enableAutomaticScroll={false}
        keyboardShouldPersistTaps="never"
        style={{ padding: 10 }}
        keyboardDismissMode="on-drag"
      >
        <TextField name="name" mandatory required title={t('folder_title')} />
        <ContributionSpreadingField filterByUserRights />
        <SubmitButton text={!isEditing ? t('put') : t('update_button')} />
      </KeyboardAwareScrollView>
    </AppScreen>
  );
}
