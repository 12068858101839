import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import Form from '../../../../components/atomic/formik/Form';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import { alertInfo, applyDateOffset } from '../../../../services/utils';
import { t } from '../../../../services/translations';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import BookingFormFields, { schema } from './BookingFormFields';
import { createBooking } from '../../../../services/api/booking.contribution.api';
import { CreateContributionBooking } from '../../../../entities/CreateContributionBooking';
import { addHours, startOfHour } from 'date-fns';

export type CreateBookingScreenProps = {
  route: {
    params?: {
      begin?: string;
    };
  };
};

export default function CreateBookingScreen({ route }: CreateBookingScreenProps) {
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();
  const begin = route.params?.begin ? new Date(route.params.begin) : startOfHour(new Date());
  const end = addHours(begin, 2);

  const { mutateAsync, isLoading, isError } = useMutation({
    mutationFn: async (values: CreateContributionBooking) => {
      await createBooking(user, {
        ...values,
        begin: new Date(applyDateOffset(values.begin)),
        end: new Date(applyDateOffset(values.end)),
      });
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
  });

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <Form
      initialValues={{ begin, end, hasIntersection: true }}
      onSubmit={mutateAsync as any}
      validationSchema={schema(user)}
      validateOnMount
    >
      <BookingFormFields />
    </Form>
  );
}
