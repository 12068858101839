import { Alert, Linking, Platform } from 'react-native';
import React, { Dispatch } from 'react';
import { ContributionType, ContributionTypesInfos } from '../types';
import { intersection, last } from 'lodash';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';
import { decode as atob } from 'base-64';
import { t } from './translations';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/es';
import 'intl/locale-data/jsonp/de';
import 'intl/locale-data/jsonp/it';
import { ScreenNames } from '../ScreenNames';
import { setContributionsListNeedsRefresh } from '../store/action';
import { UserSaved } from './storage';
import { NavigationContainerRef } from '@react-navigation/native';
import { MActu } from '../entities/MActu';
import { Contribution } from '../entities/Contribution';
import { DateTime } from 'luxon';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { megabyte } from '../components/atomic/FilePicker';
import * as Localization from 'expo-localization';

export function isMailValid(email: string) {
  const expression =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return expression.test(email.toString().toLowerCase());
}

export const openURL = (url: string, newTab = true) => {
  if (Platform.OS === 'web' && newTab) {
    window.open(forceURLPrefix(url), '_blank');
  } else if (Platform.OS === 'web') {
    window.open(forceURLPrefix(url), '_self');
  } else {
    Linking.openURL(forceURLPrefix(url));
  }
};

const forceURLPrefix = (url: string) => {
  let PROTOCOL_WHITELIST = ['http:', 'https:', 'mailto:', 'tel:'];
  for (let i = 0; i < PROTOCOL_WHITELIST.length; i++) {
    if (url.startsWith(PROTOCOL_WHITELIST[i])) {
      return url;
    }
  }

  return `https://${url}`;
};

export const isMobile = () => {
  return Platform.OS !== 'web';
  //return React.View !== undefined
};

export const isAdmin = (user: UserSaved) => user.lovIdType === 13;

export const hasSubAdminRight = (user: UserSaved, services?: number[], sitegeo?: number[], groups?: number[]) => {
  // First case, we check if the user is a sub-admin over everything
  if (
    (user.userSubAdmins?.filter(
      (e) => e.entrepriseGroupId == null && e.entrepriseServiceId == null && e.entrepriseSiteGeoId == null
    ).length || 0) > 0
  ) {
    return true;
  }

  // Second case, we check if the user is a sub-admin over the spreading
  const subAdminGroupIds = user.userSubAdmins
    ?.filter((a) => a.entrepriseGroupId != null)
    .map((a) => a.entrepriseGroupId);

  const subAdminServiceIds = user.userSubAdmins
    ?.filter((a) => a.entrepriseServiceId != null)
    .map((a) => a.entrepriseServiceId);

  const subAdminSiteGeoIds = user.userSubAdmins
    ?.filter((a) => a.entrepriseSiteGeoId != null)
    .map((a) => a.entrepriseSiteGeoId);

  const subAdminGroupIdsInUserRight = intersection(subAdminGroupIds, groups || []);
  const subAdminServiceIdsInUserRight = intersection(subAdminServiceIds, services || []);
  const subAdminSiteGeoIdsInUserRight = intersection(subAdminSiteGeoIds, sitegeo || []);

  return (
    (subAdminGroupIdsInUserRight.length === groups?.length && subAdminGroupIdsInUserRight.length !== 0) ||
    (subAdminServiceIdsInUserRight.length === services?.length && subAdminServiceIdsInUserRight.length !== 0) ||
    (subAdminSiteGeoIdsInUserRight.length === sitegeo?.length && subAdminSiteGeoIdsInUserRight.length !== 0)
  );
};

export const isSubAdmin = (user: UserSaved) => user.userSubAdmins !== undefined && user.userSubAdmins.length > 0;

export function getReadablePercent(number: number) {
  if (isNaN(number)) {
    return '-';
  }

  return (number * 100).toFixed(0) + '%';
}

export function getPlural(value: number, singular: string, plural: string) {
  if (value === 0 || value === 1) {
    return singular;
  }

  return plural;
}

export const combineNames = (userProfileData: { nom: string; prenom: string }) => {
  return (
    (userProfileData.prenom == null ? '' : userProfileData.prenom) +
    ' ' +
    (userProfileData.nom == null ? '' : userProfileData.nom)
  );
};

export function drawerIcon(iconName: string) {
  return () => <FontAwesome6 size={15} style={{ width: 24, overflow: 'hidden' }} name={iconName} />;
}

export async function _compressImage(result: any) {
  return ImageManipulator.manipulateAsync(result.assets[0].uri, [{ resize: { width: 512 } }], {
    format: ImageManipulator.SaveFormat.PNG,
  });
}

export const getFileInfo = async (fileURI: string) => {
  try {
    let fileInfo;
    if (Platform.OS !== 'web') {
      fileInfo = await FileSystem.getInfoAsync(fileURI);
    } else {
      var base64str = fileURI.split('base64,')[1];
      var decoded = atob(base64str);
      fileInfo = {
        size: decoded.length,
      };
    }
    return fileInfo;
  } catch (error) {
    console.error(error);
  }
};

export const isLessThanTheMB = (fileSize: number, smallerThanSizeMB: number) => {
  const isOk = fileSize / 1024 / 1024 < smallerThanSizeMB;
  return isOk;
};

export type PickPhotoOrDocumentResult = PickPhotoResult | PickDocumentResult;

export interface PickPhotoResult {
  name: string;
  fileName: string;
  type: string;
  width: number;
  height: number;
  uri: string;
}

export async function pickPhoto(sizeLimit: number | undefined = undefined): Promise<PickPhotoResult | Blob> {
  if (Platform.OS !== 'web') {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    if (status !== 'granted') {
      alertInfo(t('failed_camera_access'));
      throw Error('Failed to retrieve picture');
    }
  }

  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [1, 1],
    quality: 1,
    base64: false,
  });

  if (!result.canceled && result.assets && result.assets.length >= 1) {
    if (sizeLimit !== undefined) {
      const fileInfo = await getFileInfo(result.assets[0].uri);

      if (!fileInfo?.size) {
        throw Error("Can't select this file as the size is unknown.");
      }

      const validSize = isLessThanTheMB(fileInfo.size, sizeLimit);
      if (!validSize) {
        throw Error(`Image size must be smaller than ${sizeLimit}MB!`);
      }
    }

    const compressedResult = await _compressImage(result);

    if (Platform.OS === 'web') {
      let blob = await fetch(compressedResult.uri).then((r) => r.blob());
      let metadata = {
        type: 'image/png',
        lastModified: Date.now(),
      };

      let file = new File([blob], 'mypicture.png', metadata);
      file.uri = compressedResult.uri;

      return file;
    } else {
      var newPhoto = {
        name: 'mypicture.jpg',
        fileName: 'mypicture.jpg',
        type: (compressedResult as any).type || 'image/png',
        height: compressedResult.height,
        width: compressedResult.width,
        uri: compressedResult.uri,
      };
    }

    return newPhoto;
  }

  throw Error('Failed to retrieve picture');
}

export type PickDocumentResult = {
  name: string;
  fileName: string;
  type: string;
  uri: string;
  size: number;
};

export async function pickDocument(
  sizeLimitMB?: number | undefined,
  allowedExtensions?: string[] | undefined
): Promise<Blob | PickDocumentResult> {
  const result = await DocumentPicker.getDocumentAsync({ type: allowedExtensions });

  if (result.canceled === false) {
    const fileResult = result.assets[0];

    if (!fileResult) {
      throw new Error('File must be defined');
    }

    if (sizeLimitMB !== undefined && fileResult.size && fileResult.size > sizeLimitMB * megabyte) {
      throw Error(t('file_too_large'));
    }

    let { name, size, uri } = fileResult;
    let nameParts = name.split('.');
    let fileType = nameParts[nameParts.length - 1];

    if (allowedExtensions && allowedExtensions.find((e) => e.toLowerCase() === fileType.toLowerCase()) === undefined) {
      throw Error(t('file_type_incorrect', { types: allowedExtensions }));
    } else {
      if (Platform.OS === 'web' && fileResult.file !== undefined && fileResult.file !== null) {
        return fileResult.file;
      } else {
        if (Platform.OS === 'android' && uri[0] === '/') {
          uri = `file://${uri}`;
          uri = uri.replace(/%/g, '%25');
        }

        return {
          name,
          fileName: name,
          type: fileResult.mimeType || 'application/' + fileType,
          uri: uri,
          size: size || 0,
        };
      }
    }
  } else {
    throw new Error('File must be defined');
  }
}

export async function takePhoto(): Promise<PickPhotoResult | Blob> {
  if (Platform.OS !== 'web') {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    if (status !== 'granted') {
      alertInfo(t('failed_camera_access'));
      throw Error('Failed to retrieve picture');
    }
  }

  let result = await ImagePicker.launchCameraAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [1, 1],
    quality: 1,
  });
  if (!result.canceled && result.assets && result.assets.length >= 1) {
    const compressedResult = await _compressImage(result);

    var newPhoto = {
      name: 'mypicture.jpg',
      fileName: 'mypicture.jpg',
      type: (compressedResult as any).type || 'image/png',
      height: compressedResult.height,
      width: compressedResult.width,
      uri: compressedResult.uri,
    };
    return Promise.resolve(newPhoto);
  }
  throw Error('Failed to retrieve picture');
}

export function _getInterestId(
  contribution: Contribution | MActu,
  userId: number | undefined,
  contributionId: number,
  type: ContributionType
) {
  if (contribution !== undefined) {
    if (type === ContributionType.COMPANY_NEWS) {
      const interestsList = (contribution as MActu).mActuInterest;
      for (const key in interestsList) {
        if (interestsList[key].user.id === userId && interestsList[key].mActuId === contributionId) {
          return interestsList[key].id;
        }
      }
    } else {
      const interestsList = (contribution as Contribution).cinterest;
      for (const key in interestsList) {
        if (interestsList[key].user.id === userId && interestsList[key].contributionId === contributionId) {
          return interestsList[key].id;
        }
      }
    }
  }

  return undefined;
}

export function wait(ms: number) {
  return new Promise((res) => setTimeout(res, ms));
}

export const applyDateOffset = (date: Date) => {
  const newDate = new Date(date);
  const offset = newDate.getTimezoneOffset() * 60000;
  return new Date(newDate.getTime() - offset);
};

export const forceUTCToLocal = (date: string) =>
  DateTime.fromISO(date.substring(0, 'YYYY-MM-DDTHH:MM:SS.mmm'.length), {
    zone: 'UTC',
  })
    .toLocal()
    .toISO();

export function getReadableDate(
  dateText?: string | null,
  withTime?: boolean,
  forceUtc?: boolean,
  recentFormat?: boolean
) {
  if (!dateText) {
    return '';
  }
  let date = forceUtc
    ? DateTime.fromISO(dateText.substring(0, 'YYYY-MM-DDTHH:MM:SS.mmm'.length), { zone: 'UTC' }).toLocal().toJSDate()
    : new Date(dateText);

  if (!forceUtc && Platform.OS === 'android') {
    const calendars = Localization.getCalendars();
    const timezone = last(calendars)?.timeZone || 'Europe/Paris';
    const offset = DateTime.local().setZone(timezone).offset;
    date = new Date(date.getTime() - offset * 60 * 1000);
  }

  const today = new Date();
  const isNotRecent =
    (recentFormat === undefined || recentFormat) && (today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24) > 2;

  if (isNotRecent) {
    return formatDateToTimeAgo(date);
  } else {
    return withTime
      ? DateTime.fromJSDate(date).toFormat('dd/MM/yyyy HH:mm')
      : DateTime.fromJSDate(date).toFormat('dd/MM/yyyy');
  }
}

export function formatDateToTimeAgo(date: Date) {
  if (!date) {
    return '';
  }

  const difference = (new Date().getTime() - date.getTime()) / 1000;
  const seconds = Math.floor(difference);

  if (seconds < 60) {
    return t('now');
  } else if (seconds < 3600) {
    return t('minutes_ago', { minutes: Math.floor(seconds / 60) });
  } else if (seconds < 86400) {
    return t('hours_ago', { hours: Math.floor(seconds / 3600) });
  } else if (seconds < 172800) {
    return t('yesterday');
  } else if (seconds < 604800) {
    return t('days_ago', { days: Math.floor(seconds / 86400) });
  } else if (seconds < 1209600) {
    return t('last_week');
  } else if (seconds < 2592000) {
    return t('weeks_ago', { weeks: Math.floor(seconds / 604800) });
  } else if (seconds < 5184000) {
    return t('last_month');
  } else if (seconds < 31536000) {
    return t('months_ago', { months: Math.floor(seconds / 2592000) });
  } else if (seconds < 63072000) {
    return t('last_year');
  } else if (seconds) {
    return t('years_ago', { years: Math.floor(seconds / 31536000) });
  }
}

export function getReadableTime(dateText?: string, forceUtc?: boolean) {
  if (!dateText) {
    return '';
  }

  const date = forceUtc
    ? DateTime.fromISO(dateText.substring(0, 'YYYY-MM-DDTHH:MM:SS.mmm'.length), { zone: 'UTC' }).toLocal().toJSDate()
    : new Date(dateText);

  return DateTime.fromJSDate(date).toFormat('HH:mm');
}

export function getRouteFromContributionType(type: ContributionType) {
  return ContributionTypesInfos[type]?.screenName ?? ScreenNames.LoggedInHome;
}

export const submitButtonText = (
  user: UserSaved,
  id: number | undefined,
  type: ContributionType,
  services?: number[],
  sitegeo?: number[],
  groups?: number[]
): string => {
  if (!id) {
    if (type === ContributionType.DOCUMENT) {
      return t('put');
    }

    if (
      (type === ContributionType.SURVEY || type === ContributionType.COMPANY_NEWS) &&
      !isAdmin(user) &&
      !hasSubAdminRight(user, services || [], sitegeo || [], groups || [])
    ) {
      return t('send_for_validation');
    }

    return t('publish_button');
  }
  return t('update_button');
};

export const sendSuccessText = (user: UserSaved, type: ContributionType, hasRights: boolean) => {
  // If the user don't have any visible informations to be contacted,
  // we prefer to display a specific message to warn the user
  const hasEmail = !user.hideEmail && !!user.aspNetUsers?.email;
  const hasPortable = !user.hidePhone && user.portable != null && user.portable?.length !== 0;
  const hasPhone = !user.hidePhone && user.telephone != null && user.telephone?.length !== 0;
  const isContactable = hasEmail || hasPortable || hasPhone;

  if (
    !isContactable &&
    [
      ContributionType.COMPANY_NEWS,
      ContributionType.SELL,
      ContributionType.ACTIVITY,
      ContributionType.MUTUAL_AID,
      ContributionType.CARPOOL,
      ContributionType.LOST_PROPERTY,
    ].includes(type)
  ) {
    return t('send_contribution_success_no_contact');
  }

  switch (type) {
    case ContributionType.COMPANY_NEWS:
      if (hasRights) {
        return t('published_company_news');
      } else {
        return t('send_for_validation_company_news');
      }
  }
  return t('send_contribution_success');
};

export function routeFromNotification(
  dispatch: Dispatch<any>,
  notificationTitle: string,
  notificationDescription: string,
  contributionId: number,
  contributionType: ContributionType
) {
  if (dispatch != null) {
    dispatch(setContributionsListNeedsRefresh(true));
  }

  switch (notificationTitle) {
    case 'Intérêt':
      break;
    case 'Idée':
      navigationRef.current?.navigate(ScreenNames.SuggestionsAdministration, {
        screen: ScreenNames.SuggestionsToApproveList,
      });
      break;
    case 'Événement':
    case 'Evénement':
      navigationRef.current?.navigate(ScreenNames.LoggedInDrawerNavigation, {
        screen: ScreenNames.EventMainRouter,
        params: {
          screen: ScreenNames.ContributionTabs,
          params: { screen: ScreenNames.ContributionListScreen },
        },
      });
      break;
    case 'Achats/Ventes':
      navigationRef.current?.navigate(ScreenNames.LoggedInDrawerNavigation, {
        screen: ScreenNames.SellMainRouter,
        params: {
          screen: ScreenNames.ContributionTabs,
          params: { screen: ScreenNames.ContributionListScreen },
        },
      });
      break;
    case 'Kiosque':
      navigationRef.current?.navigate(ScreenNames.LoggedInDrawerNavigation, {
        screen: ScreenNames.DocumentMainRouter,
        params: {
          screen: ScreenNames.ContributionTabs,
          params: { screen: ScreenNames.ContributionListScreen },
        },
      });
      break;
    case 'Entraide':
      navigationRef.current?.navigate(ScreenNames.LoggedInDrawerNavigation, {
        screen: ScreenNames.MutualAidMainRouter,
        params: {
          screen: ScreenNames.ContributionTabs,
          params: { screen: ScreenNames.ContributionListScreen },
        },
      });
      break;
    case 'Covoiturage':
      navigationRef.current?.navigate(ScreenNames.LoggedInDrawerNavigation, {
        screen: ScreenNames.CarPoolMainRouter,
        params: {
          screen: ScreenNames.ContributionTabs,
          params: { screen: ScreenNames.ContributionListScreen },
        },
      });
      break;
    case 'Objets trouvés':
      navigationRef.current?.navigate(ScreenNames.LoggedInDrawerNavigation, {
        screen: ScreenNames.LostPropertyMainRouter,
        params: {
          screen: ScreenNames.ContributionTabs,
          params: { screen: ScreenNames.ContributionListScreen },
        },
      });
      break;
    case 'Activité':
      navigationRef.current?.navigate(ScreenNames.LoggedInDrawerNavigation, {
        screen: ScreenNames.ActivityMainRouter,
        params: {
          screen: ScreenNames.ContributionTabs,
          params: { screen: ScreenNames.ContributionListScreen },
        },
      });
      break;
    case 'Sondage':
      if (notificationDescription.includes('en attente')) {
        navigationRef.current?.navigate(ScreenNames.SurveyAdministration);
      } else {
        navigationRef.current?.navigate(ScreenNames.LoggedInDrawerNavigation, {
          screen: ScreenNames.SurveyMainRouter,
          params: {
            screen: ScreenNames.ContributionTabs,
            params: { screen: ScreenNames.ContributionListScreen },
          },
        });
      }
      break;
    case 'Modération':
      navigationRef.current?.navigate(ScreenNames.ContributionsAdministration);
      break;
    case 'Actus':
      if (notificationDescription.includes('en attente')) {
        navigationRef.current?.navigate(ScreenNames.CompanyNewsAdministration);
      } else {
        navigationRef.current?.navigate(ScreenNames.LoggedInDrawerNavigation, {
          screen: ScreenNames.LoggedInHome,
        });
      }
      break;
    default:
      console.warn('Failed to route notification. Notification title: ' + notificationTitle);
      break;
  }
}

export const navigationRef = React.createRef<NavigationContainerRef<any>>();

export function isDateValid(date: string, iso: boolean) {
  if (date === undefined || date === null) {
    return false;
  }

  if (!iso) {
    const expression = /\d\d?\/\d\d?\/\d\d\d\d/i;
    return expression.test(date.toString().toLowerCase());
  } else {
    const expression = /\d\d\d\d\-\d\d?\-\d\d?.*/i;
    return expression.test(date.toString().toLowerCase());
  }
}

export function responsiveStyle(smallScreenStyle: any, bigScreenStyle: any) {
  if (isMobile()) {
    return smallScreenStyle;
  }

  return bigScreenStyle;
}

export function getGoogleAPIKey() {
  switch (Platform.OS) {
    case 'android':
      return 'AIzaSyA3JBE4tIfleYbo_ykSYHa_kXN5SFsH4B0';
    case 'ios':
      return 'AIzaSyAvMR-QqZz6oVxZZCmb1PcDyHNQRN-EUZY';
    default:
      return 'AIzaSyCZtjYYxCtn0Ass0rXt13w0WHiHTYqiMf0';
  }
}

export function alertInfo(text: string) {
  if (isMobile()) {
    Alert.alert(t('information'), text);
  } else {
    alert(text);
  }
}

export function latitude(coordinates: string) {
  return Number(coordinates.split(';')[0]);
}

export function longitude(coordinates: string) {
  return Number(coordinates.split(';')[1]);
}

export function distincts<T>(items: T[], selector: (item: T) => string): T[] {
  if (items === undefined) {
    return [];
  }

  const uniques: { [key: string]: T } = {};
  for (const item of items) {
    if (item) {
      uniques[selector(item)] = item;
    }
  }
  return Object.values(uniques);
}

export async function uriToFile(uri: string, filename: string): Promise<File> {
  let response = await fetch(uri, {
    mode: 'no-cors',
  });
  const blob = await response.blob();
  const file = new File([blob], 'filename', { type: blob.type });
  file.uri = uri;
  file.fileName = filename;

  return file;
}
