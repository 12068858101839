import React, { useCallback, useEffect, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Feather from 'react-native-vector-icons/Feather';
import { useDispatch } from 'react-redux';
import Separator from '../../../../components/atomic/Separator';
import { DefaultBackground } from '../../../../components/containers/AppScreen';
import { TwoActionsGenericPopup } from '../../../../components/containers/TwoActionsButtonPopup';
import { t } from '../../../../services/translations';
import { alertInfo, isMobile } from '../../../../services/utils';
import { setLoading } from '../../../../store/action';
import { ALERT_COLOR, DARK_GRAY, PRIMARY_COLOR } from '../../../../styles/appColor';
import { FONT_FAMILY_DEFAULT } from '../../../../styles/globalStyle';
import { FloatingButtons } from '../../contribution/FloatingButtons';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import useContributionListRefresh from '../../../../hooks/useContributionListRefresh';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  deleteBookingResource,
  getAdminBookingResources,
  updateBookingResourceOrder,
} from '../../../../services/api/booking.contribution.api';
import { ContributionBookingResourceSummary } from '../../../../entities/ContributionBookingResourceSummary';
import { ScreenNames } from '../../../../ScreenNames';

export default function ListBookingResourcesScreen() {
  const [items, setItems] = useState<ContributionBookingResourceSummary[]>([]);
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const navigation = useNavigation<NavigationProp<any>>();

  const { isLoading, refetch: refresh } = useQuery(
    ['admin-booking-resources', user?.id],
    () => getAdminBookingResources(user),
    {
      onError: () => {
        alertInfo(t('unknown_error'));
      },
      onSuccess: (newEntries) => {
        setItems(newEntries);
      },
    }
  );

  const { mutateAsync: updateOrder } = useMutation({
    mutationFn: async (args: { id: number; index: number }) => updateBookingResourceOrder(user, args.id, args.index),
    onSuccess: (newEntries) => {
      setItems(newEntries);
    },
  });

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Refresh after an update
  useContributionListRefresh(refresh);

  const handleDelete = useCallback(
    async (id: number) => {
      await deleteBookingResource(user, id);
      refresh();
    },
    [user, refresh]
  );
  const padding = isMobile() ? 10 : 30;

  return (
    <DefaultBackground>
      <DraggableFlatList
        data={items || []}
        onDragEnd={({ data, from, to }) => {
          const itemId = items[from].id;
          setItems(data);
          updateOrder({ id: itemId, index: to });
        }}
        scrollEnabled
        style={styles.scrollView}
        containerStyle={styles.scrollView}
        contentContainerStyle={[
          styles.scrollViewContent,
          {
            paddingHorizontal: padding,
          },
        ]}
        renderItem={({ item, drag }) => (
          <View style={styles.itemContainer}>
            <Pressable style={styles.itemActions} onPressIn={drag}>
              <Feather size={24} name="menu" color={PRIMARY_COLOR} style={styles.actionIcon} />
            </Pressable>
            <TouchableOpacity
              containerStyle={styles.contentContainer}
              style={styles.contentContainer}
              onPress={() => navigation.navigate(ScreenNames.BookingAdminUpdate, { id: item.id })}
            >
              <Text style={styles.itemLabel}>{item.name}</Text>
              <Feather size={24} name="edit" color={PRIMARY_COLOR} style={styles.editButton} />
            </TouchableOpacity>
            <TwoActionsGenericPopup
              title={t('wish_to_remove')}
              button1Title={t('yes')}
              button2Title={t('no')}
              onPressButton1={() => handleDelete(item.id)}
              onPressButton2={() => {}}
            >
              <Feather size={24} name="trash" color={ALERT_COLOR} />
            </TwoActionsGenericPopup>
          </View>
        )}
        keyExtractor={(item) => item.id.toString()}
        ItemSeparatorComponent={Separator}
      />
      <FloatingButtons onPress={() => navigation.navigate(ScreenNames.BookingAdminCreate)} />
    </DefaultBackground>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  scrollViewContent: {
    paddingTop: 10,
    paddingBottom: 80,
  },
  itemContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  itemActions: {
    color: PRIMARY_COLOR,
    minWidth: 24,
  },
  actionIcon: {
    marginRight: 10,
  },
  contentContainer: {
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  itemLabel: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 16,
    color: DARK_GRAY,
  },
  editButton: {
    marginRight: 10,
  },
});
