import {
  KeyboardType,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextInputFocusEventData,
  View,
  ViewStyle,
} from 'react-native';
import AppText, { Mode } from './AppText';
import React, { LegacyRef, ReactNode, useCallback, useEffect, useState } from 'react';
import { AppTextInput, AppTextInputProps } from './AppTextInput';
import { Chevron } from 'react-native-shapes';
import { globalStyle } from '../../styles/globalStyle';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/es';
import { AppRTEEditor } from './AppRTEEditor';
import { AppDateTimePicker } from './AppDateTimePicker';
import { ALERT_COLOR, PRIMARY_COLOR } from '../../styles/appColor';
import SelectDropdown from 'react-native-select-dropdown';
import { t } from '../../services/translations';
import { Picker } from '@react-native-picker/picker';
import { isMobile } from '../../services/utils';

export function FormField(props: {
  ref?: LegacyRef<View>;
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
  title?: string;
}) {
  return (
    <View ref={props.ref} style={[{ marginBottom: 20 }, props.style]}>
      {props.title !== undefined && (
        <AppText style={{ fontSize: 12, marginBottom: 10, color: PRIMARY_COLOR }} mode={Mode.BOLD}>
          {props.title}
        </AppText>
      )}
      {props.children}
    </View>
  );
}

export type TextFormFieldProps = {
  mandatory?: boolean;
  placeholder?: string;
  maxLength?: number;
  displayOnly?: boolean;
  multiline?: boolean;
  style?: StyleProp<ViewStyle>;
  onSubmitEditing?: any;
  title?: string;
  defaultValue?: string;
  value?: string;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters' | undefined;
  onChangeText: (data: string) => void;
  keyboardType?: KeyboardType;
  secureTextEntry?: boolean;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
} & AppTextInputProps;

export function TextFormField({ title, style, ...props }: TextFormFieldProps) {
  return (
    <FormField title={title} style={style}>
      <AppTextInput {...props} />
    </FormField>
  );
}

export type NumberFormFieldProps = Omit<Omit<TextFormFieldProps, 'value'>, 'onChangeText'> & {
  value: number | null;
  onChange: (value: number | null) => void;
};

export function NumberFormField({ value, onChange, style, title, onBlur, ...rest }: NumberFormFieldProps) {
  const [text, setText] = useState<string>('');
  const [invalid, setInvalid] = useState(false);

  // Reset text when numeric value change
  useEffect(() => {
    if (value !== null) {
      setText(value.toString());
    }
  }, [value]);

  const handleBlur = useCallback(() => {
    const parsed = parseInt(text);
    setInvalid(isNaN(parsed));
    onChange(parsed);
  }, [onBlur, onChange, text]);

  return (
    <FormField style={style} title={title}>
      <AppTextInput value={text} onChangeText={setText} onBlur={handleBlur} {...rest}></AppTextInput>
      {invalid && <Text style={styles.errorLabel}>{t('incorrect_number')}</Text>}
    </FormField>
  );
}

export function RTEFormField(props: {
  style?: StyleProp<ViewStyle>;
  defaultValue?: string;
  onChangeText: (data: string) => void;
  title: string;
}) {
  return (
    <FormField style={props.style} title={props.title}>
      <AppRTEEditor defaultValue={props.defaultValue} onChangeText={props.onChangeText} />
    </FormField>
  );
}

export type DateFieldProps = {
  withTime?: boolean;
  displayOnly?: boolean;
  style?: StyleProp<ViewStyle>;
  title: string;
  defaultValue?: string;
  onChangeText(text: string): void;
};

export function DateField(props: DateFieldProps) {
  let formattedDate = new Date();
  if (props.defaultValue !== undefined && props.defaultValue !== null) {
    formattedDate = new Date(props.defaultValue);
  }

  useEffect(() => {
    setTimeout(() => {
      props.onChangeText(formattedDate.toISOString());
    }, 100);
  }, []);

  return (
    <FormField style={props.style} title={props.title}>
      <AppDateTimePicker withTime={props.withTime} formattedDate={formattedDate} onChangeText={props.onChangeText} />
    </FormField>
  );
}

export function AppPickerSelect<T>(props: {
  pickerStyle?: StyleProp<ViewStyle>;
  displayOnly?: boolean;
  defaultValue?: T;
  defaultChoiceOnMobile?: boolean;
  defaultButtonText?: string;
  hideDefaultButton?: boolean;
  onChangeText(data: T | null): void;
  data: { label: string; value: T | null }[];
}) {
  const textStyle = { ...globalStyle.appText };

  if (isMobile()) {
    return (
      <SelectDropdown
        data={
          props.defaultChoiceOnMobile
            ? [{ label: props.defaultButtonText, value: props.defaultButtonText }, ...props.data]
            : props.data
        }
        buttonTextAfterSelection={(item) => item.label}
        rowTextForSelection={(item) => item.label}
        defaultValue={props.data.filter((v, k) => v.value === props.defaultValue)[0]}
        onSelect={(item) => {
          props.onChangeText(item.value);
        }}
        disabled={props.displayOnly}
        defaultButtonText={props.defaultButtonText || t('select_an_item')}
        buttonStyle={Object.assign({}, globalStyle.pickerTextInput, props.pickerStyle)}
        buttonTextStyle={textStyle}
        rowTextStyle={textStyle}
        renderDropdownIcon={() => <Chevron size={1.5} color="gray" />}
      />
    );
  } else {
    const dataContainsDefaultValue = props.data.filter((v, k) => v.value === props.defaultValue).length > 0;

    return (
      <Picker
        enabled={!props.displayOnly}
        style={[globalStyle.pickerTextInputWeb, props.pickerStyle]}
        onValueChange={(_, itemIndex) => {
          if (props.hideDefaultButton) {
            itemIndex = itemIndex + 1;
          }

          if (itemIndex === 0) {
            props.onChangeText(null);
          } else {
            const item = props.data[itemIndex - 1];
            props.onChangeText(item.value);
          }
        }}
        selectedValue={dataContainsDefaultValue ? props.defaultValue : 'null_or_undefined'}
      >
        {!props.hideDefaultButton && (
          <Picker.Item label={props.defaultButtonText || t('select_an_item')} value="null_or_undefined" />
        )}
        {props.data.map((v, k) => (
          <Picker.Item key={k} label={v.label} value={v.value} />
        ))}
      </Picker>
    );
  }
}

export type FormFieldPickerProps<T> = {
  displayOnly?: boolean;
  inputStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  onSubmitEditing?: any;
  defaultChoiceOnMobile?: boolean;
  defaultButtonText?: string;
  title: string;
  defaultValue: T | undefined;
  onChangeText(data: T): void;
  data: { label: string; value: T }[];
};

export function FormFieldPicker<T>(props: FormFieldPickerProps<T>) {
  return (
    <FormField style={props.style} title={props.title}>
      <View style={{ opacity: !props.displayOnly ? 1 : 0.5 }}>
        <AppPickerSelect<T>
          pickerStyle={props.inputStyle}
          displayOnly={props.displayOnly}
          defaultChoiceOnMobile={props.defaultChoiceOnMobile}
          defaultValue={props.defaultValue}
          onChangeText={props.onChangeText}
          data={props.data}
          defaultButtonText={props.defaultButtonText}
        />
      </View>
    </FormField>
  );
}

const styles = StyleSheet.create({
  errorLabel: {
    color: ALERT_COLOR,
    fontSize: 12,
    paddingLeft: 8,
  },
});
