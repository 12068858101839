export enum ModuleEnum {
  News = 0,
  Admin = 1,
  ContributionVente = 2,
  ContributionHobbie = 3,
  ContributionService = 4,
  ContributionCovoiturage = 5,
  ContributionEvenement = 6,
  ContributionFormation = 7,
  ContributionOrganigrame = 8,
  ContributionObjet = 9,
  ContributionSondage = 10,
  ContributionBoiteIdee = 11,
  ContributionKiosque = 12,
  UserDirectory = 13,
  EmergencySms = 14,
  Booking = 15,
}

export interface ModuleSettings {
  id: number;
  dateCreated: string;
  dateUpdated: string;
  module: ModuleEnum;
  order: number;
  entrepriseId: number;
}
