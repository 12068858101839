import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ScreenNames } from '../ScreenNames';
import ListBookingResourcesScreen from '../screens/loggedIn/admin/booking/ListBookingResourcesScreen';
import { t } from '../services/translations';
import BackIcon from '../components/atomic/BackIcon';
import UpdateBookingResourcesScreen from '../screens/loggedIn/admin/booking/UpdateBookingResourcesScreen';
import CreateBookingResourcesScreen from '../screens/loggedIn/admin/booking/CreateBookingResourcesScreen';

const Stack = createStackNavigator();

export function BookingAdminRouter() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: true, headerBackTitleVisible: false, headerBackImage: BackIcon }}>
      <Stack.Screen
        name={ScreenNames.BookingAdminMain}
        component={ListBookingResourcesScreen}
        options={{
          title: t('bookings'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.BookingAdminCreate}
        component={CreateBookingResourcesScreen}
        options={{
          title: t('bookings'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.BookingAdminUpdate}
        component={UpdateBookingResourcesScreen}
        options={{
          title: t('bookings'),
        }}
        initialParams={{ allowEdit: true }}
      />
    </Stack.Navigator>
  );
}
