import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import * as Localization from 'expo-localization';
import { Calendar, ICalendarEventBase } from 'react-native-big-calendar';
import { ActivityIndicator, Pressable, StyleSheet, Text, View } from 'react-native';
import { Button } from '@rneui/base';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import AppText from '../atomic/AppText';
import { match } from 'ts-pattern';
import { language, t } from '../../services/translations';
import { DARK_GRAY, PRIMARY_COLOR, WHITE } from '../../styles/appColor';
import { AppPickerSelect } from '../atomic/FormField';
import { deleteBooking, listBookingByResourceId } from '../../services/api/booking.contribution.api';
import useLoggedUser from '../../hooks/useLoggedUser';
import { alertInfo } from '../../services/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ContributionBookingSummary } from '../../entities/ContributionBookingSummary';
import { UserSubRow } from '../atomic/UserSubRow';
import { apiTimeZone } from '../../../configuration';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { ScreenNames } from '../../ScreenNames';
import { TwoActionsGenericPopup } from '../containers/TwoActionsButtonPopup';
import { addDays, differenceInDays, endOfDay, isSameDay, setHours, startOfDay, startOfHour } from 'date-fns';
import useContributionListRefresh from '../../hooks/useContributionListRefresh';

export type BookingCalendarProps = {
  resourceId: number;
  onPress: (date: Date) => void;
};

export default function BookingCalendar({ resourceId, onPress }: BookingCalendarProps) {
  const loggedUser = useLoggedUser();
  const languageCode = language().split('-')[0];
  const [calendarMode, setCalendarMode] = useState<'week' | 'month'>('week');
  const [displayedDate, setDisplayedDate] = useState(dayjs().locale(languageCode));
  const navigation = useNavigation<NavigationProp<any>>();

  const begin = match(calendarMode)
    .with('week', () => displayedDate.subtract(1, 'week').startOf('week').toDate())
    .with('month', () => displayedDate.subtract(1, 'month').startOf('month').toDate())
    .exhaustive();

  const end = match(calendarMode)
    .with('week', () => displayedDate.add(1, 'week').endOf('week').toDate())
    .with('month', () => displayedDate.add(1, 'month').endOf('month').toDate())
    .exhaustive();

  const { data, isLoading, refetch } = useQuery(
    ['booking-resources', loggedUser?.id, resourceId, begin.toISOString(), end.toISOString()],
    () => listBookingByResourceId(loggedUser, resourceId, begin, end),
    {
      onError: () => {
        alertInfo(t('unknown_error'));
      },
    }
  );

  const bookings = useMemo(() => {
    // If a booking is reparted on many days, we need to cut it
    if (calendarMode === 'week') {
      return data?.reduce((acc, item) => {
        const start = new Date(item.begin);
        const end = new Date(item.end);

        if (isSameDay(start, end)) {
          return acc.concat(item);
        } else {
          // First day
          acc.push({
            ...item,
            begin: start.toISOString(),
            end: endOfDay(start).toISOString(),
          });

          // Days between
          const daysDiff = differenceInDays(end, start);
          for (let i = 1; i < daysDiff; i++) {
            acc.push({
              ...item,
              begin: startOfDay(addDays(start, i)).toISOString(),
              end: endOfDay(addDays(start, i)).toISOString(),
            });
          }

          // Last day
          acc.push({
            ...item,
            begin: startOfDay(end).toISOString(),
            end: end.toISOString(),
          });

          return acc;
        }
      }, [] as ContributionBookingSummary[]);
    } else {
      return data;
    }
  }, [data, calendarMode]);

  // Refresh after an update
  useContributionListRefresh(refetch);

  const { mutateAsync: deleteBookingMutation } = useMutation({
    mutationFn: async (id: number) => {
      await deleteBooking(loggedUser, id);
      await refetch();
    },
  });

  const onNext = useCallback(
    () =>
      setDisplayedDate((displayedDate) =>
        match(calendarMode)
          .with('week', () => displayedDate.add(1, 'week'))
          .with('month', () => displayedDate.add(1, 'month'))
          .exhaustive()
      ),
    [calendarMode]
  );

  const onPrev = useCallback(
    () =>
      setDisplayedDate((displayedDate) =>
        match(calendarMode)
          .with('week', () => displayedDate.subtract(1, 'week'))
          .with('month', () => displayedDate.subtract(1, 'month'))
          .exhaustive()
      ),
    [calendarMode]
  );

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={PRIMARY_COLOR} />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.controlsContainer}>
        <View style={styles.calendarButtons}>
          <Button
            icon={<FontAwesome6 name="arrow-left" size={12} color={DARK_GRAY} />}
            onPress={onPrev}
            style={styles.button}
            buttonStyle={styles.buttonButton}
          />
          <Button
            title={t('today')}
            onPress={() => setDisplayedDate(dayjs().locale(languageCode))}
            titleStyle={styles.buttonText}
            style={styles.button}
            buttonStyle={styles.buttonButton}
          />
          <Button
            icon={<FontAwesome6 name="arrow-right" size={12} color={DARK_GRAY} />}
            onPress={onNext}
            style={styles.button}
            buttonStyle={styles.buttonButton}
          />
        </View>
        <AppText>
          {match(calendarMode)
            .with('week', () => t('week_of', { date: displayedDate.format('DD/MM/YYYY') }))
            .with('month', () => displayedDate.format('MM/YYYY'))
            .exhaustive()}
        </AppText>
        <AppPickerSelect<'week' | 'month'>
          defaultValue={calendarMode}
          hideDefaultButton
          onChangeText={(value) => {
            setCalendarMode(value || 'week');
          }}
          data={[
            {
              label: t('week'),
              value: 'week',
            },
            {
              label: t('month'),
              value: 'month',
            },
          ]}
        />
      </View>
      <Calendar<ICalendarEventBase & Omit<ContributionBookingSummary, 'start' | 'end'>>
        events={
          bookings
            ? bookings.map((item) => ({
                ...item,
                title: '',
                start: new Date(item.begin),
                end: new Date(item.end),
              }))
            : []
        }
        renderEvent={(event, pressableProps) => (
          <Pressable
            {...pressableProps}
            style={
              calendarMode === 'week'
                ? [pressableProps.style, styles.eventPressableWeek]
                : [pressableProps.style, styles.eventPressableMonth]
            }
          >
            {calendarMode === 'week' && (
              <View style={styles.eventContainer}>
                {event.authorId === loggedUser.id && (
                  <View style={styles.eventActions}>
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate(ScreenNames.BookingUpdate, { id: event.id });
                      }}
                    >
                      <FontAwesome6 size={16} name="edit" color={WHITE} />
                    </TouchableOpacity>
                    <TwoActionsGenericPopup
                      title={t('wish_to_remove')}
                      button1Title={t('yes')}
                      button2Title={t('no')}
                      onPressButton1={() => deleteBookingMutation(event.id)}
                      onPressButton2={() => {}}
                    >
                      <FontAwesome6 size={16} name="trash" color={WHITE} />
                    </TwoActionsGenericPopup>
                  </View>
                )}
                <View style={styles.eventContent}>
                  <Text style={styles.eventLabel}>
                    {formatInTimeZone(zonedTimeToUtc(event.start, apiTimeZone), Localization.timezone, 'HH:mm') +
                      ' - ' +
                      formatInTimeZone(zonedTimeToUtc(event.end, apiTimeZone), Localization.timezone, 'HH:mm')}
                  </Text>
                </View>
                <UserSubRow user={event.author} withPicture textColor="white" />
              </View>
            )}
          </Pressable>
        )}
        height={600}
        mode={calendarMode}
        locale={language().split('-')[0]}
        weekStartsOn={1}
        date={displayedDate.toDate()}
        swipeEnabled={false}
        onPressCell={(date) => {
          match(calendarMode)
            .with('week', () => onPress(startOfHour(date)))
            .with('month', () => onPress(setHours(startOfDay(date), 8)))
            .exhaustive();
        }}
        onPressEvent={(ev) => {
          match(calendarMode)
            .with('week', () => undefined)
            .with('month', () => onPress(setHours(startOfDay(ev.start), 8)))
            .exhaustive();
        }}
        onPressDateHeader={(date) => {
          onPress(setHours(startOfDay(date), 8));
        }}
        showVerticalScrollIndicator
        moreLabel={`{moreCount} ${t('others')}`}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {},
  controlsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 10,
    marginVertical: 8,
  },
  calendarButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  button: {
    borderColor: DARK_GRAY,
    borderWidth: 1,
    borderRadius: 4,
  },
  buttonButton: {
    backgroundColor: 'transparent',
  },
  buttonText: {
    fontSize: 12,
    lineHeight: 16,
    color: DARK_GRAY,
  },
  eventPressableWeek: {
    backgroundColor: 'transparent',
    elevation: 0,
    padding: 2,
    boxShadow: 'none',
    transform: [{ translateY: -2.5 }],
  },
  eventPressableMonth: {
    flex: 1,
    backgroundColor: PRIMARY_COLOR,
  },
  eventContainer: {
    flex: 1,
    backgroundColor: PRIMARY_COLOR,
    padding: 10,
    borderRadius: 4,
  },
  eventActions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 8,
  },
  eventContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  eventLabel: {
    fontSize: 12,
    lineHeight: 16,
    color: 'white',
  },
});
