import React from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Yup from 'yup';
import ContributionSpreadingField, {
  contributionSpreadingFieldValidators,
} from '../../../../components/atomic/formik/ContributionSpreadingField';
import FolderPickerField from '../../../../components/atomic/formik/FolderPickerField';
import SubmitButton from '../../../../components/atomic/formik/SubmitButton';
import TextField from '../../../../components/atomic/formik/TextField';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { t } from '../../../../services/translations';

export const schema = Yup.object({
  title: Yup.string().min(1).required(t('field_required')),
  ...contributionSpreadingFieldValidators,
});

export type FolderFormFieldsProps = {
  isEditing?: boolean | undefined;
};

export default function FolderFormFields({ isEditing }: FolderFormFieldsProps) {
  return (
    <AppScreen>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        enableAutomaticScroll={false}
        keyboardShouldPersistTaps="never"
        style={{ padding: 10 }}
        keyboardDismissMode="on-drag"
      >
        <TextField name="title" mandatory required title={t('folder_title')} />
        <FolderPickerField name="parentId" title={t('parent_folder')} parentFolder />
        <ContributionSpreadingField filterByUserRights />
        <SubmitButton text={!isEditing ? t('put') : t('update_button')} />
      </KeyboardAwareScrollView>
    </AppScreen>
  );
}
