import React, { useEffect } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Yup from 'yup';
import SubmitButton from '../../../../components/atomic/formik/SubmitButton';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { t } from '../../../../services/translations';
import { useQuery } from '@tanstack/react-query';
import { alertInfo } from '../../../../services/utils';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import {
  checkResourceIntersectWithOtherBooking,
  getBookingResources,
} from '../../../../services/api/booking.contribution.api';
import { SelectField } from '../../../../components/atomic/formik/SelectField';
import DateField from '../../../../components/atomic/formik/DateField';
import { StyleSheet, View } from 'react-native';
import { UserSaved } from '../../../../services/storage';
import FieldHelper from '../../../../components/atomic/formik/FieldHelper';
import { useFormikContext } from 'formik';
import { addMinutes } from 'date-fns';

export const schema = (loggedUser: UserSaved, ignoreBookingId?: number) =>
  Yup.object({
    contributionBookingResourceId: Yup.number().required(t('field_required')),
    begin: Yup.date().required(t('field_required')),
    end: Yup.date().required(t('field_required')),
    hasIntersection: Yup.mixed().test('is-valid-range', t('booking_already_exist'), async function () {
      const { begin, end, contributionBookingResourceId } = this.parent;
      if (!begin || !end || !contributionBookingResourceId) {
        return true;
      }

      return !(await checkResourceIntersectWithOtherBooking(
        loggedUser,
        contributionBookingResourceId,
        begin,
        end,
        ignoreBookingId
      ));
    }),
  });

export type BookingFormFieldsProps = {
  isEditing?: boolean | undefined;
};

export default function BookingFormFields({ isEditing }: BookingFormFieldsProps) {
  const loggedUser = useLoggedUser();

  const { values, validateForm, setFieldValue } = useFormikContext<any>(); // Get form values & validation function

  const { data: resources } = useQuery(['booking-resources', loggedUser?.id], () => getBookingResources(loggedUser), {
    onError: () => {
      alertInfo(t('unknown_error'));
    },
  });

  // Trigger validation when `begin` or `end` changes
  useEffect(() => {
    if (values.contributionBookingResourceId && values.begin && values.end) {
      validateForm();
    }
  }, [values.contributionBookingResourceId, values.begin, values.end, validateForm]);

  // Force end to be at least greater than begin 5min
  useEffect(() => {
    if (values.begin && values.end) {
      if (values.end < addMinutes(values.begin, 5)) {
        setFieldValue('end', addMinutes(values.begin, 5));
      }
    }
  }, [values.begin, values.end, setFieldValue]);

  return (
    <AppScreen>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        enableAutomaticScroll={false}
        keyboardShouldPersistTaps="never"
        style={styles.container}
        keyboardDismissMode="on-drag"
      >
        <SelectField
          name="contributionBookingResourceId"
          title={t('resource')}
          required
          data={
            resources?.map((e) => ({
              label: e.name,
              value: e.id,
            })) || []
          }
        />
        <View style={styles.dateContainer}>
          <DateField name="begin" title={t('date')} withTime />
          <DateField name="end" title={t('end_date')} withTime />
        </View>
        <FieldHelper name="hasIntersection" displayIfNotTouched />
        <SubmitButton text={!isEditing ? t('put') : t('update_button')} />
      </KeyboardAwareScrollView>
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  dateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
});
