import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { View } from 'react-native';
import { useMutation, useQuery } from '@tanstack/react-query';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import Form from '../../../../components/atomic/formik/Form';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import { alertInfo } from '../../../../services/utils';
import { t } from '../../../../services/translations';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import { UpdateContributionBookingResource } from '../../../../entities/UpdateContributionBookingResource';
import { getBookingResourceDetails, updateBookingResource } from '../../../../services/api/booking.contribution.api';
import BookingResourcesFormFields, { schema } from './BookingResourcesFormFields';

export type UpdateBookingResourcesScreenProps = {
  route: {
    params: {
      id?: string;
    };
  };
};

export default function UpdateBookingResourcesScreen({ route }: UpdateBookingResourcesScreenProps) {
  const user = useLoggedUser();
  const id = (route.params.id && parseInt(route.params.id, 10)) || null;
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const {
    data,
    isLoading: isLoadingQuery,
    isError: isErrorQuery,
    isFetched,
  } = useQuery(['bookingResourceDetails', id], () => getBookingResourceDetails(user, id || 0), { cacheTime: 0 });

  const {
    mutateAsync,
    isLoading: isLoadingMutation,
    isError: isErrorMutation,
  } = useMutation({
    mutationFn: async (values: UpdateContributionBookingResource) => {
      if (!id) {
        throw new Error('Missing id');
      }

      await updateBookingResource(user, id, values);
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
  });

  const isLoading = isLoadingQuery || isLoadingMutation;
  const isError = isErrorQuery || isErrorMutation;

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return isFetched ? (
    <Form
      initialValues={{
        name: data?.name,
        spreadingType: data?.contribution?.spreadingType,
        userIds: data?.contribution?.userTargets.map((e) => e.userId),
        entrepriseServiceIds: data?.contribution?.entrepriseServices.map((e) => e.entrepriseServiceId),
        entrepriseSiteGeoIds: data?.contribution?.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeoId),
        entrepriseGroupIds: data?.contribution?.entrepriseGroups.map((e) => e.entrepriseGroupId),
      }}
      onSubmit={mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <BookingResourcesFormFields isEditing />
    </Form>
  ) : (
    <View />
  );
}
