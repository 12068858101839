import React, { useCallback, useEffect, useMemo } from 'react';
import { createDrawerNavigator, DrawerNavigationOptions } from '@react-navigation/drawer';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { CustomDrawerContent } from './CustomDrawerContent';
import { t } from '../services/translations';
import { ContributionRouter } from './ContributionRouter';
import { DirectoryList } from '../screens/loggedIn/DirectoryScreen';
import { ContributionType } from '../types';
import { ContributionItemDetailScreen } from '../screens/loggedIn/contribution/ContributionItemDetailScreen';
import { ContributionFormScreen } from '../screens/loggedIn/contribution/ContributionFormScreen';
import { drawerIcon, isMobile } from '../services/utils';
import { LoggedInHomeScreen } from '../screens/loggedIn/LoggedInHomeScreen';
import { ScreenNames } from '../ScreenNames';
import {
  hasLicenceActivity,
  hasLicenceCarPool,
  hasLicenceDocument,
  hasLicenceEvent,
  hasLicenceTraining,
  hasLicenceLostProperty,
  hasLicenceMutualAid,
  hasLicenceSell,
  hasLicenceOrganizationalChart,
  hasLicenceSuggestion,
  hasLicenceSurvey,
  hasLicenceEmergencySms,
  hasLicenceBooking,
} from '../services/licences';
import { AdminRoute } from './AdminRoute';
import { BackButton } from '../components/atomic/BackButton';
import CreateNewsScreen from '../screens/loggedIn/contribution/news/CreateNewsScreen';
import NewsDetailScreen from '../screens/loggedIn/contribution/news/NewsDetailScreen';
import { HeaderButtons } from '../components/atomic/HeaderButtons';
import { MessagingRoute } from './MessagingRoute';
import OrganizationChartsScreen from '../screens/loggedIn/OrganizationChartsScreen';
import { DocumentsRouter } from './DocumentsRouter';
import { CustomModuleRouter } from './CustomModuleRouter';
import DetailsCustomModuleContributionScreen from '../screens/loggedIn/contribution/custom/DetailsCustomModuleContributionScreen';
import { getPictureURI } from '../services/api/helper.api';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { Image, View } from 'react-native';
import useLoggedUser from '../hooks/useLoggedUser';
import useReviewApp from '../hooks/useReviewApp';
import { useDeviceSize } from '../hooks/useDeviceSize';
import { EmergencySmsRouter } from './EmergencySmsRouter';
import DrawerToggleButton from '../components/atomic/DrawerToggleButton';
import { useDispatch } from 'react-redux';
import { fetchUserData } from '../services/storage';
import useCustomModules from '../hooks/useCustomModules';
import { ModuleEnum } from '../entities/ModuleSettings';
import { getModuleDefaultOrder } from '../services/module';
import { sortBy } from 'lodash';
import UpdateNewsScreen from '../screens/loggedIn/contribution/news/UpdateNewsScreen';
import DuplicateNewsScreen from '../screens/loggedIn/contribution/news/DuplicateNewsScreen';
import { BookingRouter } from './BookingRouter';

const Drawer = createDrawerNavigator();

export default function LoggedInDrawerNavigation() {
  let loggedUser = useLoggedUser();
  const dispatch = useDispatch();

  const userEmail = loggedUser.aspNetUsers.email;
  const userEmailDomain = userEmail.split('@')[1];
  const entrepriseDomains = loggedUser.entreprise.entrepriseNomDomaine.map((e) => e.nomDomaine);
  const moduleSettings = loggedUser.entreprise.moduleSettings;
  const isUserInDomain = entrepriseDomains.includes(userEmailDomain);

  useReviewApp();
  const { isSmallDevice } = useDeviceSize();
  const { customModules } = useCustomModules();

  // Refetch user data every minute
  useEffect(() => {
    const interval = setInterval(() => {
      fetchUserData(loggedUser.token, dispatch);
    }, 1 * 60 * 1000);

    return () => clearInterval(interval);
  }, [loggedUser.token, dispatch]);

  const defaultScreenOptions: DrawerNavigationOptions = useMemo(
    () =>
      isMobile()
        ? {
            headerShown: true,
            headerTitleAlign: 'center',
            headerRight: HeaderButtons,
          }
        : {
            headerShown: true,
            headerTitleAlign: 'center',
            headerRight: HeaderButtons,
          },
    []
  );

  const getOrderByModule = useCallback(
    (module: ModuleEnum) => {
      return moduleSettings.find((x) => x.module === module)?.order ?? getModuleDefaultOrder(module);
    },
    [moduleSettings]
  );

  const drawerItems = useMemo(() => {
    let result: (
      | {
          order: number;
          element: React.JSX.Element;
        }
      | null
      | undefined
    )[] = [
      {
        order: getOrderByModule(ModuleEnum.News),
        element: (
          <Drawer.Screen
            key={ScreenNames.LoggedInHome}
            name={ScreenNames.LoggedInHome}
            component={LoggedInHomeScreen}
            options={{
              ...defaultScreenOptions,
              title: t('home'),
              drawerIcon: drawerIcon('house'),
            }}
          />
        ),
      },
      loggedUser.lovIdType === 13 || (loggedUser.userSubAdmins?.length ?? 0) > 0
        ? {
            order: getOrderByModule(ModuleEnum.Admin),
            element: (
              <Drawer.Screen
                key={ScreenNames.AdminRoute}
                name={ScreenNames.AdminRoute}
                component={AdminRoute}
                options={{
                  ...defaultScreenOptions,
                  title: t('admin_page_title'),
                  drawerIcon: drawerIcon('gear'),
                }}
              />
            ),
          }
        : null,
      hasLicenceSell(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.ContributionVente),
            element: (
              <Drawer.Screen
                key={ScreenNames.SellMainRouter}
                name={ScreenNames.SellMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.SELL,
                  title: t('contribution_sell'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_sell'),
                  drawerIcon: drawerIcon('tags'),
                }}
              />
            ),
          }
        : null,
      hasLicenceActivity(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.ContributionHobbie),
            element: (
              <Drawer.Screen
                key={ScreenNames.ActivityMainRouter}
                name={ScreenNames.ActivityMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.ACTIVITY,
                  title: t('contribution_activity'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_activity'),
                  drawerIcon: drawerIcon('rocket'),
                }}
              />
            ),
          }
        : null,
      hasLicenceMutualAid(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.ContributionService),
            element: (
              <Drawer.Screen
                key={ScreenNames.MutualAidMainRouter}
                name={ScreenNames.MutualAidMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.MUTUAL_AID,
                  title: t('contribution_mutual_aid'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_mutual_aid'),
                  drawerIcon: drawerIcon('question-circle'),
                }}
              />
            ),
          }
        : null,
      hasLicenceCarPool(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.ContributionCovoiturage),
            element: (
              <Drawer.Screen
                key={ScreenNames.CarPoolMainRouter}
                name={ScreenNames.CarPoolMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.CARPOOL,
                  title: t('contribution_carpool'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_carpool'),
                  drawerIcon: drawerIcon('car'),
                }}
              />
            ),
          }
        : null,
      hasLicenceEvent(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.ContributionEvenement),
            element: (
              <Drawer.Screen
                key={ScreenNames.EventMainRouter}
                name={ScreenNames.EventMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.EVENT,
                  title: t('contribution_event'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_event'),
                  drawerIcon: drawerIcon('calendar'),
                }}
              />
            ),
          }
        : null,
      hasLicenceTraining(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.ContributionFormation),
            element: (
              <Drawer.Screen
                key={ScreenNames.TrainingMainRouter}
                name={ScreenNames.TrainingMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.FORMATION,
                  title: t('contribution_training'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_training'),
                  drawerIcon: drawerIcon('graduation-cap'),
                }}
              />
            ),
          }
        : null,
      hasLicenceBooking(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.Booking),
            element: (
              <Drawer.Screen
                key={ScreenNames.BookingRouter}
                name={ScreenNames.BookingRouter}
                component={BookingRouter}
                options={{
                  ...defaultScreenOptions,
                  title: t('bookings'),
                  drawerIcon: () => (
                    <MaterialCommunityIcons size={15} style={{ width: 24, overflow: 'hidden' }} name="calendar-check" />
                  ),
                }}
              />
            ),
          }
        : null,
      hasLicenceOrganizationalChart(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.ContributionOrganigrame),
            element: (
              <Drawer.Screen
                key={ScreenNames.OrganizationCharts}
                name={ScreenNames.OrganizationCharts}
                component={OrganizationChartsScreen}
                options={{
                  ...defaultScreenOptions,
                  title: t('organizational_charts'),
                  drawerIcon: drawerIcon('sitemap'),
                }}
              />
            ),
          }
        : null,
      hasLicenceLostProperty(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.ContributionObjet),
            element: (
              <Drawer.Screen
                key={ScreenNames.LostPropertyMainRouter}
                name={ScreenNames.LostPropertyMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.LOST_PROPERTY,
                  title: t('contribution_lost_property'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_lost_property'),
                  drawerIcon: drawerIcon('key'),
                }}
              />
            ),
          }
        : null,
      hasLicenceSurvey(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.ContributionSondage),
            element: (
              <Drawer.Screen
                key={ScreenNames.SurveyMainRouter}
                name={ScreenNames.SurveyMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.SURVEY,
                  title: t('i_say'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('i_say'),
                  drawerIcon: drawerIcon('box'),
                }}
              />
            ),
          }
        : null,
      hasLicenceSuggestion(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.ContributionBoiteIdee),
            element: (
              <Drawer.Screen
                key={ScreenNames.SuggestionMainRouter}
                name={ScreenNames.SuggestionMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.SUGGESTION,
                  title: t('suggestions_box'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('suggestions_box'),
                  drawerIcon: drawerIcon('lightbulb'),
                }}
              />
            ),
          }
        : null,
      hasLicenceDocument(loggedUser)
        ? {
            order: getOrderByModule(ModuleEnum.ContributionKiosque),
            element: (
              <Drawer.Screen
                key={ScreenNames.DocumentMainRouter}
                name={ScreenNames.DocumentMainRouter}
                component={DocumentsRouter}
                initialParams={{
                  contributionType: ContributionType.DOCUMENT,
                  title: t('kiosk'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('kiosk'),
                  drawerIcon: drawerIcon('file'),
                }}
              />
            ),
          }
        : null,
      {
        order: getOrderByModule(ModuleEnum.UserDirectory),
        element: (
          <Drawer.Screen
            key={ScreenNames.Directory}
            name={ScreenNames.Directory}
            component={DirectoryList}
            options={{
              ...defaultScreenOptions,
              title: t('directory_page_title'),
              drawerIcon: drawerIcon('users'),
            }}
          />
        ),
      },
      hasLicenceEmergencySms(loggedUser) && isUserInDomain
        ? {
            order: getOrderByModule(ModuleEnum.EmergencySms),
            element: (
              <Drawer.Screen
                key={ScreenNames.EmergencySmsRouter}
                name={ScreenNames.EmergencySmsRouter}
                component={EmergencySmsRouter}
                options={{
                  ...defaultScreenOptions,
                  title: t('sms_alerts'),
                  drawerIcon: () => (
                    <MaterialCommunityIcons size={15} style={{ width: 24, overflow: 'hidden' }} name="alarm-light" />
                  ),
                }}
              />
            ),
          }
        : null,
      ...(customModules
        ? customModules.map((customModule) => ({
            order: customModule.order,
            element: (
              <Drawer.Screen
                key={customModule.identifier}
                name={customModule.identifier}
                component={CustomModuleRouter}
                initialParams={{
                  moduleIdentifier: customModule.identifier,
                }}
                options={{
                  ...defaultScreenOptions,
                  title: customModule.title,
                  drawerIcon: () =>
                    customModule.iconId ? (
                      <View style={{ width: 24, height: 15, overflow: 'hidden' }}>
                        <Image style={{ width: 15, height: 15 }} source={getPictureURI(customModule.iconId)} />
                      </View>
                    ) : (
                      <FontAwesome6 size={15} style={{ width: 24, overflow: 'hidden' }} name="undefined" />
                    ),
                }}
              />
            ),
          }))
        : []),
    ];

    return sortBy(
      result.filter((e) => !!e) as {
        order: number;
        element: React.JSX.Element;
      }[],
      'order'
    ).map((e) => e.element);
  }, [loggedUser, customModules, defaultScreenOptions, isUserInDomain, getOrderByModule]);

  return (
    <Drawer.Navigator
      screenOptions={{
        unmountOnBlur: true,
        drawerType: isMobile() || isSmallDevice ? 'front' : 'permanent',
        headerLeft: isMobile() || isSmallDevice ? DrawerToggleButton : () => <></>,
      }}
      drawerContent={CustomDrawerContent}
      useLegacyImplementation={false}
      backBehavior="history"
    >
      {drawerItems}
      {/* Hidden drawer screens */}
      <Drawer.Screen
        key={ScreenNames.ContributionItemDetailScreen}
        name={ScreenNames.ContributionItemDetailScreen}
        component={ContributionItemDetailScreen}
        options={{
          drawerItemStyle: { display: 'none' },
          drawerLabel: () => null,
          headerShown: false,
          title: t('contribution'),
        }}
      />
      <Drawer.Screen
        key={ScreenNames.ContributionEditFormScreen}
        name={ScreenNames.ContributionEditFormScreen}
        component={ContributionFormScreen}
        options={{
          drawerItemStyle: { display: 'none' },
          headerShown: true,
          headerLeft: () => <BackButton relative />,
          title: t('redact'),
        }}
      />
      <Drawer.Screen
        key={ScreenNames.CreateCompanyNews}
        name={ScreenNames.CreateCompanyNews}
        component={CreateNewsScreen}
        options={{
          drawerItemStyle: { display: 'none' },
          headerShown: true,
          headerLeft: () => <BackButton higher absoluteScreenName={ScreenNames.LoggedInHome} relative />,
          title: t('redact'),
        }}
      />
      <Drawer.Screen
        key={ScreenNames.EditCompanyNews}
        name={ScreenNames.EditCompanyNews}
        component={UpdateNewsScreen}
        options={{
          drawerItemStyle: { display: 'none' },
          headerShown: true,
          headerLeft: () => <BackButton higher absoluteScreenName={ScreenNames.LoggedInHome} relative />,
          title: t('redact'),
        }}
      />
      <Drawer.Screen
        key={ScreenNames.DuplicateCompanyNews}
        name={ScreenNames.DuplicateCompanyNews}
        component={DuplicateNewsScreen}
        options={{
          drawerItemStyle: { display: 'none' },
          headerShown: true,
          headerLeft: () => <BackButton higher absoluteScreenName={ScreenNames.LoggedInHome} relative />,
          title: t('redact'),
        }}
      />
      <Drawer.Screen
        key={ScreenNames.DetailCompanyNews}
        name={ScreenNames.DetailCompanyNews}
        component={NewsDetailScreen}
        options={{
          drawerItemStyle: { display: 'none' },
          headerShown: false,
          title: t('company_news'),
        }}
      />
      <Drawer.Screen
        key={ScreenNames.CustomModuleDetails}
        name={ScreenNames.CustomModuleDetails}
        component={DetailsCustomModuleContributionScreen}
        options={{
          drawerItemStyle: { display: 'none' },
          headerShown: false,
          title: '',
        }}
      />
      <Drawer.Screen
        key={ScreenNames.Messaging}
        name={ScreenNames.Messaging}
        component={MessagingRoute}
        options={{
          drawerItemStyle: { display: 'none' },
          headerShown: false,
          title: t('messaging'),
        }}
      />
    </Drawer.Navigator>
  );
}
